import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import {library} from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import {faAddressCard, faArrowCircleRight, faArrowRight, faBan, faCalendar, faCaretDown, faCaretRight, faCheck, faCheckCircle, faCopy, faCrosshairs, faDirections, faEdit, faEnvelope, faFileImage, faFilter, faLongArrowAltLeft, faLongArrowAltRight, faMapMarkerAlt, faPhone, faPlusCircle, faRedo, faRetweet, faSearch, faSearchMinus, faSearchPlus, faSignOutAlt, faStar, faSync, faThumbsDown, faThumbsUp, faTimesCircle, faTrashAlt, faUndo, faXmark,} from '@fortawesome/free-solid-svg-icons'

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
import {createRoutesFromChildren, useLocation, useNavigationType} from 'react-router'
import {matchRoutes} from 'react-router-dom'

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: "https://0e56869165f040c4a1afe185c1b36a45@o4503969963245568.ingest.sentry.io/4504157397909504",
    integrations: [new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        ),
    })],
    ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
    ],
    tracesSampleRate: 0.2,
    release: 'protocol-ui@' + process.env.REACT_APP_VERSION
});

library.add(faUndo, faRedo, faFileImage, faCalendar, faPlusCircle, faCheck, faCheckCircle, faSignOutAlt, faTimesCircle, faTrashAlt, faEdit, faSearch, faSearchMinus, faSearchPlus, faLongArrowAltRight, faLongArrowAltLeft, faArrowCircleRight, faRetweet, faBan, faStar, faPhone, faMapMarkerAlt, faSync, faCopy, faArrowRight, faThumbsUp, faThumbsDown, faCrosshairs, faDirections, faFilter, faEnvelope, faAddressCard, faCaretRight, faCaretDown, faXmark, )

registerLocale('de', de)
setDefaultLocale('de')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
