import {useEffect, useRef, useState} from 'react';
import {clamp} from "./utils";

export function useDraggable(initialPos, boundingBoxRef) {
    const targetRef = useRef(null)
    const [dragging, setDragging] = useState(null)
    const [pos, setPos] = useState(initialPos || {x:0.5, y:0.5})

    useEffect(() => {
        const handle = targetRef.current
        handle.addEventListener('mousedown', startDragging)
        handle.addEventListener('touchstart', startDragging)
        handle.style.position = "absolute"
        boundingBoxRef.current.style.position = "relative"

        return () => {
            handle.removeEventListener('mousedown', startDragging)
            handle.removeEventListener('touchstart', startDragging)
        }

        function startDragging(event) {
            event.preventDefault()
            setDragging(true)
        }
    }, [boundingBoxRef])

    useEffect(() => {
        const handle = targetRef.current
        if (dragging) {
            document.addEventListener('mousemove', onMove, { passive: true })
            document.addEventListener('touchmove', onMove, { passive: true })
            document.addEventListener('mouseup', stopDragging)
            document.addEventListener('touchend', stopDragging)
        } else {
            cleanup()
        }

        handle.style.cursor = dragging ? 'crosshair' : ''
        boundingBoxRef.current.style.touchAction = dragging ? "none" : ""

        return () => {
            cleanup()
        }

        function cleanup() {
            const handle = targetRef.current
            if (handle) {
                handle.style.cursor = ''
            }
            if (boundingBoxRef.current) {
                boundingBoxRef.current.style.touchAction = ""
            }
            document.removeEventListener('mousemove', onMove, { passive: true })
            document.removeEventListener('mouseup', stopDragging)
            document.removeEventListener('touchmove', onMove, { passive: true })
            document.removeEventListener('touchend', stopDragging)
        }

        function stopDragging(event) {
            event.preventDefault()
            setDragging(false)
        }

        function onMove(event) {
            const source =
                (event.changedTouches && event.changedTouches[0]) ||
                (event.touches && event.touches[0]) ||
                event
            const r = boundingBoxRef.current.getBoundingClientRect()
            setPos({x: clamp((source.clientX - r.x) / r.width, 0, 1), y: clamp((source.clientY - r.y) / r.height, 0, 1)})
        }
    }, [dragging, boundingBoxRef])

    useEffect(() => {
        targetRef.current.style.left = `${pos.x * 100}%`
        targetRef.current.style.top = `${pos.y * 100}%`
    }, [pos])

    return { targetRef, dragging, pos }
}
