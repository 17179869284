import {useCallback, useState} from "react";

export function clamp(v:number, min:number, max:number) {
    return Math.min(Math.max(v, min), max);
}

export const convertBase64ImageToBlob = (image: string): Blob => {
    const parts = image.split(';base64,')
    const mimeType = parts[0].split(':')[1]
    const bytes = Uint8Array.from(
        atob(parts[1])
            .split('')
            .map(char => char.charCodeAt(0))
    )
    return new Blob([bytes], { type: mimeType })
}

export function useClientRect(): [
    ClientRect | undefined,
    (node: HTMLDivElement) => void
] {
    const [rect, setRect] = useState<ClientRect>()

    const ref = useCallback((node: HTMLDivElement) => {
        console.log(node)
        if (node !== null) {
            console.log(node.getBoundingClientRect())
            setRect(node.getBoundingClientRect())
        }
    }, [])

    return [rect, ref]
}

export function uuidv4() {
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function filterObject(obj: any, key: string) {
    for (let i in obj) {
        if (typeof obj[i] == 'object') {
            filterObject(obj[i], key);
        } else if (i === key) {
            delete obj[key];
        }
    }
    return obj;
}

export function getCurrentPosition(): Promise<GeolocationPosition | undefined> {
    // @ts-ignore
    const exportGeoLocationInApp = !window.ReactNativeWebView || window.__hasExtGeoSupport
    return new Promise((resolve) => {
        if (exportGeoLocationInApp && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => resolve(pos), e => {
                console.log('Could not get geo position:', e)
                resolve(undefined)
            })
        } else {
            resolve(undefined)
        }
    })
}

export interface Point {
    latitude: number
    longitude: number
}

export function haversineDistance(pointA: Point, pointB: Point): number {
    //convert latitude and longitude to radians
    const deltaLatitude = (pointB.latitude - pointA.latitude) * Math.PI / 180
    const deltaLongitude = (pointB.longitude - pointA.longitude) * Math.PI / 180

    const halfChordLength = Math.cos(
            pointA.latitude * Math.PI / 180) * Math.cos(pointB.latitude * Math.PI / 180)
        * Math.sin(deltaLongitude/2) * Math.sin(deltaLongitude/2)
        + Math.sin(deltaLatitude/2) * Math.sin(deltaLatitude/2)

    const angularDistance = 2 * Math.atan2(Math.sqrt(halfChordLength), Math.sqrt(1 - halfChordLength))

    // distance in meters
    return 6371 * 1000 * angularDistance
}

export async function blobToBase64(blob: Blob) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const dataUrl = event.target?.result as string;
            resolve(dataUrl?.split(',')[1])
        }
        reader.readAsDataURL(blob)
    })
}
