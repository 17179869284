export const LICENCE_PLATE_VALIDATIONS = {
    B: /^(.+){2,}$/,
    BG: /^(.+){2,}$/,
    CH: /^(.+){2,}$/,
    DK: /^(.+){2,}$/,
    D: /^[A-ZÄÖÜ]{1,3}-[A-ZÄÖÜ]{1,2} [1-9][0-9]{0,3}E?$/,
    EST: /^(.+){2,}$/,
    FIN: /^(.+){2,}$/,
    F: /^(.+){2,}$/,
    GR: /^(.+){2,}$/,
    IRL: /^(.+){2,}$/,
    I: /^(.+){2,}$/,
    HR: /^(.+){2,}$/,
    LV: /^(.+){2,}$/,
    LT: /^(.+){2,}$/,
    L: /^(.+){2,}$/,
    M: /^(.+){2,}$/,
    NL: /^(.+){2,}$/,
    A: /^(.+){2,}$/,
    PL: /^(.+){2,}$/,
    P: /^(.+){2,}$/,
    RO: /^(.+){2,}$/,
    S: /^(.+){2,}$/,
    SK: /^(.+){2,}$/,
    SLO: /^(.+){2,}$/,
    E: /^(.+){2,}$/,
    CZ: /^(.+){2,}$/,
    H: /^(.+){2,}$/,
    GB: /^(.+){2,}$/,
    CY: /^(.+){2,}$/,
}
export type LicencePlateCountry = keyof typeof LICENCE_PLATE_VALIDATIONS
