import Dexie, { Table } from 'dexie';

export interface FileData {
  uuid: string
  blob: ArrayBuffer
  type: string
}

export interface ProtocolData {
  uuid: string
  data: any
  files: FileData[]
}

class ProtocolsDB extends Dexie {
  protocols!: Table<ProtocolData>;

  constructor() {
    super("protocolsDB");
    this.version(1).stores({
      protocols: `
        uuid,
        data,
        *files`
    });
  }
}

export const db = new ProtocolsDB()
