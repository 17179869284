import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SignatureCanvas from './sig-canvas'

export interface SignatureRef {
    getImage: () => string | null
    setImage: (dataUrl: string) => void
}

export interface SignatureProps {
    onChange?: (canvas: HTMLCanvasElement | null) => void
}

const SignatureForward: React.ForwardRefRenderFunction<SignatureRef, SignatureProps> = ({onChange, ...props}, ref) => {
    const sigRef = useRef<SignatureCanvas | null>()
    const wrapRef = useRef<HTMLDivElement>(null)

    function drawImageCentered(dataUrl: string) {
        sigRef.current!.clear()
        const canvas = sigRef.current!.getCanvas()
        const image = new Image()
        image.onload = () => {
            if (!canvas.width || !canvas.height) {
                canvas.width = image.width
                canvas.height = image.height
            }
            const ratio = image.naturalWidth / image.naturalHeight
            const w = canvas.width
            const h = w / ratio
            canvas.getContext("2d")!.drawImage(image, (canvas.width - w) / 2, (canvas.height - h) / 2, w, h)
        }
        image.src = dataUrl

    }
    useImperativeHandle(ref, () => ({
        getImage: (): string | null => {
            const canvas = sigRef.current!.getTrimmedCanvas()
            if (canvas.width > 50 && canvas.height > 50) {
                return canvas.toDataURL("image/png")
            }
            return null
        },
        setImage: dataUrl => {
            drawImageCentered(dataUrl)
        }
    }))

    const setupDimensions = useCallback(() => {
        const canvas = sigRef.current!.getCanvas()
        const dataUrl = canvas.toDataURL("image/png")
        const {width, height} = wrapRef.current!.getBoundingClientRect()
        if (canvas.width !== width || canvas.height !== height) {
            canvas.width = width
            canvas.height = height
            drawImageCentered(dataUrl)
        }
    }, [])

    useEffect(() => {
        function clicked() {
            const canvas = sigRef.current!.getTrimmedCanvas()
            if (canvas.width > 50 && canvas.height > 50) {
                onChange!(canvas)
            } else {
                onChange!(null)
            }
        }
        if (onChange && sigRef.current?._sigPad) {
            sigRef.current._sigPad.addEventListener("endStroke", clicked)
        }

        const resizeObserver = new ResizeObserver((entries) => {
            setupDimensions()
        })
        const div = wrapRef.current!
        resizeObserver.observe(div)

        return () => {
            resizeObserver.unobserve(div)
            resizeObserver.disconnect()
            if (onChange && sigRef.current?._sigPad) {
                sigRef.current._sigPad.removeEventListener("endStroke", clicked)
            }
        }
    }, [onChange, setupDimensions])

    function clearSignature() {
        sigRef.current!.clear()
        onChange && onChange(null)
    }
    return (
        <div className="signature" ref={wrapRef}>
            <SignatureCanvas clearOnResize={false} ref={r => sigRef.current = r}></SignatureCanvas>
            <FontAwesomeIcon size="2x" color="#ccc" icon="times-circle" onClick={clearSignature} />
        </div>

    )
}

export const Signature = forwardRef(SignatureForward)
