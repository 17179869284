import * as React from 'react';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {getCurrentPosition, haversineDistance, Point} from './utils';

export interface GeoLocationContextProps {
    currentLocation?: Point
    isNearLocation: (location: Point, radius: number) => boolean
}

const GeoLocationContext = createContext<GeoLocationContextProps>({} as GeoLocationContextProps)

export const useGeoLocation = () => useContext(GeoLocationContext)

export default function GeoLocationContextProvider({children}: any) {
    const [currentLocation, setCurrentLocation] = useState<Point | undefined>()
    const [checked, setChecked] = useState<boolean>(false)

    useEffect(() => {
        getCurrentPosition().then(r => {
            r?.coords && setCurrentLocation(r.coords)
        }).catch(() => setCurrentLocation(undefined))
    }, [])

    const isNearLocation = useCallback((location: Point, radius: number = 500) => {
        if (checked) {
            // Skipping geo check, assume true...
            return true
        }
        setChecked(true)
        return !!currentLocation && haversineDistance(location, currentLocation) < radius
    }, [checked, currentLocation])

    const context: GeoLocationContextProps = useMemo(() => ({
        currentLocation,
        isNearLocation,
    }), [currentLocation, isNearLocation])

    return (
        <GeoLocationContext.Provider value={context}>{children}</GeoLocationContext.Provider>
    )
}
