import React, {forwardRef, useEffect, useState} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Controller, FormProvider, useFormContext} from 'react-hook-form';
import get from 'lodash/get';
import DatePicker from 'react-datepicker';
import {useI18n} from './i18n'

export const MyForm = ({onSubmit, children, methods, ...props}: any) => {
    return (
        <Form noValidate onSubmit={methods.handleSubmit(onSubmit)} {...props}>
            <FormProvider {...methods}>
                {children}
            </FormProvider>
        </Form>
    )
}

export const FormGroup = ({children}: any) => <Form.Group className="mb-2">{children}</Form.Group>

export const FormField = ({name, type = undefined, placeholder = undefined, append = undefined, ...props}: any) => {
    const { errors, setValue, control, getValues } = useFormContext()
    const {t} = useI18n()

    useEffect(() => {
        get(getValues(), name) || setValue(name, type === CheckBox ? false : '')
    }, [setValue, getValues, name, type])

    if (type === CheckBoxes) {
        // special case for multiple checkboxes: every box must be controlled
        return props.options.map((o: any, idx: number) => <FormField type={CheckBox} label={o.label} name={`${name}[${o.value}]`} key={`${name}_${idx}`} />)
    }
    const error = get(errors, name)
    return (
        <>
            <Controller as={type || Form.Control} defaultValue={(type === CheckBox ? false : '')} isInvalid={!!get(errors, name)} name={name} placeholder={placeholder} control={control} append={append} {...props} />
            {error &&
            <Form.Control.Feedback type="invalid">
                {t(error.message.key, error.message.params)}
            </Form.Control.Feedback>
            }
        </>
    )
}

export const TextUppercase = forwardRef(({name, value, onChange, ...props}: any, ref) => {
    return <Form.Control name={name} value={value} onChange={e => onChange(e.target.value?.toUpperCase())} {...props} ref={ref} />
})

export const Textarea = forwardRef(({name, ...props}: any, ref) =>
    <Form.Control as="textarea" name={name} {...props} ref={ref} />
)

export const Select = forwardRef(({name, options, ...props}: any, ref) => {
    const {t} = useI18n()
    return <Form.Select name={name} {...props} ref={ref}>
        <option value="">{t('pleaseChoose')}</option>
        {options?.map((o: any, idx: number) => {
            const v = typeof o === 'object' ? o.value : o
            const l = typeof o === 'object' ? o.label || v : o
            return <option key={`${name}_${idx}`} value={v}>{l}</option>
        })}
    </Form.Select>
})

export const CheckBox = forwardRef(({name, value, onChange, ...props}: any, ref) =>
    <Form.Check checked={value} id={`__checkbox-id-${name}`} onChange={e => onChange(e.target.checked)} ref={ref} {...props} />)

// just a marker component -- magic happens in FormField :)
export const CheckBoxes = <></>

export const RadioBoxes = forwardRef(({name, label, options, value, onChange,...props}: any, ref) =>
    options?.map((o: any, idx: number) => {
        const v = typeof o === 'object' ? o.value : o
        const l = typeof o === 'object' ? o.label || v : o
        return <Form.Check checked={v === value} type="radio" key={`${name}_${idx}`} value={v} label={l} id={`__radio-id-${name}_${idx}`} onChange={e => onChange(e.target.value)} {...props} />
    })
)


export const IntegerInput = forwardRef<HTMLInputElement>((props: any, ref) =>
    <InputGroup>
        <Form.Control {...props} ref={ref} />
        <InputGroup.Text>{props.append || ''}</InputGroup.Text>
    </InputGroup>)

export const DecimalInput = forwardRef<HTMLInputElement>((props, ref) => <Form.Control type="number" step="0.01" min="0" {...props} ref={ref} />)

export const DateTimeInput = forwardRef((props, ref) => <DateTimeInputImpl showTime {...props} ref={ref} />)
export const DateInput = forwardRef((props, ref) => <DateTimeInputImpl {...props} ref={ref} />)

const DateTimeInputImpl = forwardRef(({name, showTime, isInvalid, value, onChange, placeholder, readOnly, allowFutureDates, ...props}: any, ref) => {
    const [date, setDate] = useState<Date | null>()
    useEffect(() => {
        setDate(value ? new Date(value) : null)
    }, [value])
    const {t} = useI18n()
    return (
        <DatePicker {...props} maxDate={allowFutureDates ? undefined : new Date()} readOnly={!!readOnly} isClearable={!readOnly} timeCaption={t('DatePicker.timeCaption')} timeInputLabe={t('DatePicker.timeCaption')} showTimeSelect={showTime} timeIntervals={5} ref={ref} selected={date} dateFormat={showTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"} onChange={d => {setDate(d); onChange((d && d.toISOString().substring(0, showTime ? undefined : 10)) || '')}}  placeholderText={placeholder} customInput={<CustomDateInput isInvalid={isInvalid} />} showMonthDropdown showYearDropdown dropdownMode="select" />
    )
})

const CustomDateInput = forwardRef(({onClick, ...props}: any, ref) => (
    <InputGroup>
        <Form.Control {...props} readOnly={true} style={{backgroundColor:'white'}} />
        <InputGroup.Text onClick={onClick}><FontAwesomeIcon icon="calendar"/></InputGroup.Text>
    </InputGroup>
))
